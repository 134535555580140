import React from 'react'
import { Link } from "gatsby"
import Header from '../components/Header'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import HiringSection from '../components/Hiring'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 37.118, lng: -79.722 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 37.118, lng: -79.722 }} />}
  </GoogleMap>
))

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="about-us">
        <Helmet
      title="Contact Us | Bishop Software"
      meta={[
        { name: 'description', content: 'Contact us about custom software development, web design, mobile development, software consulting, and user experience for your business.' },
        { name: 'keywords', content: 'contact,software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
      ]}
    />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, rgba(3,57,115,1) 0%, rgba(44,136,148,1) 73%, rgba(26,174,196,1) 90%, rgba(33,225,255,1) 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#24a4bb 0px, rgb(20, 80, 142) 100%)' section="about-us" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
            <h1 style={{color: '#ffc107', fontSize: '40px', fontWeight: 300}}>
                Contact Us
              </h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>Ask us something. Tell us something. Who knows, it could be the start of something.</h3>
              <div style={{marginTop: '35px', paddingBottom: '35px'}}>
                <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
              </div>

              
            </div>
          </Container>
        </section>

      <Scroll.Element name="contact" />
    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

    <section style={{backgroundImage: 'linear-gradient(175deg, #2d4f5f 0, #188d98 80%, #3eb8b6 100%)', padding: '70px 0'}}>
          <Container>
            <Row>
              <h1 style={{color: '#ffc107', textAlign: 'center', width: '100%', paddingBottom: '10px', borderBottom: '1px dashed #ffc107', fontWeight: 300}}>Contact Us Old School</h1>
            </Row>
            <Row style={{marginTop: '40px'}}>
              <Col xs="12" md="4" style={{textAlign: 'center', color: '#fff', paddingRight: '20px'}}>
                <h4 style={{color: '#ffc107', margin: '10px 0 5px 0'}}><i className="fas fa-phone fa-xs"></i> &nbsp;Phone</h4>
                <a href="tel:18334247467" style={{color: "#fff", fontWeight: 400}}>+1 (833) 4-BISHOP</a><br />
                <a href="tel:18334247467" style={{color: "#fff", fontWeight: 400}}>(1-833-424-7467)</a><br />
                <h4 style={{color: '#ffc107', margin: '10px 0 5px 0'}}><i className="fas fa-envelope fa-xs"></i> &nbsp;Email</h4>
                <a href="mailto:info@bishopsoft.com" style={{color: '#fff', fontWeight: 400}}>info@bishopsoft.com</a><br />
                <br />
                <h4 style={{color: '#ffc107', margin: '10px 0 5px 0'}}><i className="fas fa-map-marker fa-xs"></i> &nbsp;Address</h4>
                <div style={{lineHeight: '22px'}}>
                84 Westlake Rd.<br />
                STE 103<br />
                Hardy, VA 24101<br />
                United States<br /><br />
                </div>
              </Col>
              <Col xs="12" md="8">
              <MyMapComponent
      isMarkerShown
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC-ZcfAg2AXXXPpURWjVHY_dBzUCVdc_Nk&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
              </Col>
            </Row>
          </Container>
        </section>

        <HiringSection />

        <Footer section="about-us" />
      </div>
      </Layout>
    )
  }
}
  